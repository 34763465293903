import {useForm} from 'react-hook-form';
import {Button, Box, Stack, Text} from '@chakra-ui/react';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {useRouter} from 'next/router';
import Link from 'next/link';
import React, {useEffect} from 'react';
import {useCognito} from '../../hooks/cognito';
import {TextInputField} from '../forms/text-input-field';
import {Form} from '../forms/form';
import {useUserQuery} from '../../graphql/generated';
import {useAppToast} from '../../hooks/app-toast';
import {theme} from '../../style/theme';
import {TRACK_LINK_ID} from '../../utils/mixpanel';
import {GoogleButton, Divider} from './google-social-sign-on';
import {Routes} from '../../shared/consts';

const schema = yup
  .object({
    username: yup.string().required(),
    password: yup.string().required(),
  })
  .required();

const LogIn: React.FC<{
  showTitle?: React.ReactElement;
  onClick?: () => void;
  redirect?: string;
}> = ({showTitle, onClick, redirect}) => {
  const userQuery = useUserQuery();
  const router = useRouter();
  const toast = useAppToast();
  const cognito = useCognito();
  const reactForm = useForm<{username: string; password: string}>({
    resolver: yupResolver(schema),
  });
  const onSubmit = (data: {username: string; password: string}) => {
    // Clear localstorage on login as a workaround for this: https://bugs.webkit.org/show_bug.cgi?id=245479
    localStorage.clear();

    return cognito
      .signIn(data.username.toLowerCase(), data.password)
      .then((user) => {
        if (
          user.attributes.email_verified &&
          redirect === Routes.VERIFICATION
        ) {
          // eslint-disable-next-line no-param-reassign
          redirect = '/';
        }

        cognito.handleRedirect(redirect);
      })
      .catch((e) => {
        if (e.code === 'UserNotFoundException') {
          let message = 'This email address is not in use';
          cognito
            .validateUserSocialLoginAccount(data.username.toLowerCase())
            .then((provider) => {
              if (provider) {
                message = `Account already registered with ${provider}`;
              }

              reactForm.setError('username', {
                message,
              });
            });
        } else if (e.code === 'UserNotConfirmedException') {
          toast.showErrorToast(
            "Your account hasn't been verified, please email us at info@showpiece.com and we will assist you",
          );
        } else if (e.code === 'NotAuthorizedException') {
          reactForm.setError('password', {
            message: 'Incorrect email address or password',
          });
        } else {
          throw e;
        }
      });
  };

  useEffect(() => {
    if (
      userQuery.data?.user &&
      !reactForm.formState.isSubmitted &&
      !reactForm.formState.isDirty &&
      !reactForm.formState.isSubmitting
    ) {
      toast.showErrorToast(
        'We were unable to complete your request because you’re already logged in',
        'You’re already signed in!',
      );
      router.replace('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    userQuery.data?.user,
    reactForm.formState.isSubmitted,
    reactForm.formState.isDirty,
    reactForm.formState.isSubmitting,
  ]);

  return (
    <Box px={{base: '28px', md: '64px'}} py="40px" layerStyle="card" w="100%">
      {showTitle || null}
      {process.env.GOOGLE_AUTH_ENABLED === 'true' && (
        <>
          <GoogleButton />
          <Divider />
        </>
      )}
      <Form
        {...reactForm}
        onSubmit={onSubmit}
        onError={(e) => toast.showErrorToast(e)}
      >
        <Stack spacing="9">
          <TextInputField
            name="username"
            type="email"
            autoComplete="email"
            label="Email"
          />
          <TextInputField
            name="password"
            type="password"
            autoComplete="current-password"
            label="Password"
          />
        </Stack>
        <Button
          width="100%"
          mt={10}
          colorScheme="aqua"
          isLoading={reactForm.formState.isSubmitting}
          type="submit"
        >
          Log In
        </Button>
        <Link href="/reset-password">
          <a>
            <Button width="100%" mt={3} variant="ghost" colorScheme="aqua">
              Forgot your password?
            </Button>
          </a>
        </Link>

        <Text mt={3} color="charcoal" textStyle="button" textAlign="center">
          New to Showpiece?{' '}
          {onClick ? (
            <Text as="span" color="aqua.500" onClick={onClick}>
              Create an account
            </Text>
          ) : (
            <Link href="/create-account">
              <a
                className={TRACK_LINK_ID.REGISTER}
                style={{color: theme.colors.aqua['500']}}
              >
                Create an account
              </a>
            </Link>
          )}
        </Text>
      </Form>
    </Box>
  );
};

export {LogIn};
