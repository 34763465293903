import {Box, Flex, Image, Text} from '@chakra-ui/react';
import mixpanel from 'mixpanel-browser';
import {FC, useCallback} from 'react';
import {useCognito} from '../../hooks/cognito';
import {theme} from '../../style/theme';

interface IGoogleButton {
  signup?: boolean;
}

export const GoogleButton = ({signup = false}: IGoogleButton): JSX.Element => {
  const cog = useCognito();
  const onClick = useCallback(() => {
    mixpanel.track('Social Sign in', {
      type: signup ? 'signup' : 'signin',
    });

    cog.ssoSignIn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cog]);

  return (
    <Flex
      as="button"
      textAlign="left"
      width="100%"
      border="1px solid rgba(0, 0, 0, 0.1)"
      backgroundColor={theme.colors.white}
      borderRadius="14px"
      onClick={onClick}
    >
      <Flex py={3} pl={4} alignItems="center">
        <Box mr={4}>
          <Image
            src="/assets/googleIcon.svg"
            height="24px"
            width="24px"
            alt="Google Icon"
          />
        </Box>
        <Text textStyle="socialSignIn" color="black">
          {signup ? 'Sign Up with Google' : 'Continue with Google'}
        </Text>
      </Flex>
    </Flex>
  );
};

const Line = () => (
  <Flex width="45%" height="1px" backgroundColor="tone5" ml="4px" mr="4px" />
);

export const Divider: FC<{text?: string}> = ({text}): JSX.Element => (
  <Flex
    width="100%"
    justifyContent="space-between"
    alignItems="center"
    mt="16px"
    mb="16px"
  >
    <Line />
    <Text textStyle="body3" color="tone5">
      {text ?? 'or'}
    </Text>
    <Line />
  </Flex>
);
