import {useFormContext} from 'react-hook-form';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
} from '@chakra-ui/react';
import React from 'react';

interface Props {
  name: string;
  label: string;
  type?: string;
  autoComplete?: string;
  capitalise?: boolean;
}

export const TextInputField: React.FC<Props> = (props) => {
  const {
    formState: {errors},
    register,
  } = useFormContext();
  const onChange = props.capitalise
    ? (e: React.ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;
        if (value && value.length > 0) {
          const [first, ...rest] = value.split('');
          e.target.value = first.toUpperCase() + rest.join('');
        }
      }
    : undefined;
  return (
    <FormControl isInvalid={!!errors[props.name]}>
      <FormLabel color="tone3" htmlFor={props.name}>
        {props.label}
      </FormLabel>
      <Input
        id={props.name}
        autoComplete={props.autoComplete}
        type={props.type}
        {...register(props.name, {
          onChange,
        })}
        height="48px"
        bgColor="white"
      />
      <FormErrorMessage>
        {(errors?.[props?.name]?.message as string) ?? ''}
      </FormErrorMessage>
    </FormControl>
  );
};
