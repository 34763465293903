import Head from 'next/head';
import {Box, Container} from '@chakra-ui/react';
import {PageLayout} from '../components/global/page-layout';
import {useUserQuery} from '../graphql/generated';
import {LogIn} from '../components/auth/log-in';
import {Routes} from '../shared/consts';

const SignInPage: React.FC = () => {
  const userQuery = useUserQuery();

  return (
    <>
      <Head>
        <script src="https://accounts.google.com/gsi/client" async defer />
      </Head>
      <PageLayout
        firstName={userQuery.data?.user?.firstName}
        title="Log in | Showpiece"
      >
        <Box
          textAlign="center"
          mb="8"
          textStyle={{base: 'h5', md: 'h3'}}
          as="h3"
        >
          Log In
        </Box>
        <Container maxW="container.sm" p={0}>
          <LogIn redirect={Routes.MY_COLLECTION} />
        </Container>
      </PageLayout>
    </>
  );
};

export default SignInPage;
