import {
  FieldValues,
  FormProvider,
  SubmitHandler,
  UseFormReturn,
} from 'react-hook-form';
import React from 'react';

interface Props<T extends FieldValues> extends UseFormReturn<T> {
  onSubmit: SubmitHandler<T>;
  onError: (e: Error) => void;
}

export const Form = <T extends FieldValues>({
  onSubmit,
  onError,
  ...props
}: React.PropsWithChildren<Props<T>>): React.ReactElement<any, any> => {
  const submitHandler = props.handleSubmit((data, event) =>
    (onSubmit(data as any, event) as Promise<any>).catch(onError),
  );
  return (
    <FormProvider {...(props as any)}>
      <form onSubmit={submitHandler} style={{width: '100%'}}>
        {props.children}
      </form>
    </FormProvider>
  );
};
